export const formatCurrencyIDR = (number: number): string => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  export const formatPrice = (number: number): string => {
    if (number >= 1000) {
        if (number >= 1000000) {
            return "Rp."+`${(number / 1000000).toFixed(0)}jt`;
        }
        return "Rp."+`${(number / 1000).toFixed(0)}K`; // Changes 450.000 to 450K
      }
      return "Rp."+ number.toString();
  };

  export const formatDate = (number: string): string => {
  if(number == 'MONTHLY'){
    return "month"
  }
  return "year"
  };


  export const formatDateNew = (dateString: string): string => {
    try {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    } catch (error) {
        console.error('Invalid date string:', dateString, error);
        return dateString; // Return the original string if formatting fails
    }
};
export const formatDateNoHour = (dateString: string): string => {
    try {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${day}-${month}-${year} `;
    } catch (error) {
        console.error('Invalid date string:', dateString, error);
        return dateString; // Return the original string if formatting fails
    }
};
//   function (value) {
//     // Converts the number to a simplified format

//   }
